.root {
  position: fixed;
  height: 70px;
  width: 100%;
  /* border-bottom: 1px solid gainsboro; */
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0px 30px;

  @media (max-width: 850px) {
    padding: 0px 24px;
  }
}

.bgHeaderDropdown {
  min-height: 48px;
  background-color: #40C057;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.menuMember {
  position: absolute;
  top: 18px;
  left: 14px;
}