.headerLayout {
  position: fixed;
  height: 180px;
  width: 100%;
  border-bottom: 1px solid gainsboro;
  overflow: hidden;
  z-index: 1;

  @media (max-width: 768px) {
    height: 80px;
  }
}

.root {
  height: 80px;
  padding: 10px 0px;
  width: 100%;
  /* border-bottom: 1px solid gainsboro; */
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0px 30px;

  @media (max-width: 850px) {
    padding: 0px 24px;
  }
}

.menuHeader {
  height: 100%;
  padding: 0px 20px;

  @media (max-width: 850px) {
    padding: 0px 16px;
  }
}

.bodyLayout {
  margin-top: 200px;
  z-index: 0;
}

.bgHeaderDropdown {
  min-height: 48px;
  background-color: #40C057;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.menuMember {
  position: absolute;
  top: 18px;
  left: 14px;
}