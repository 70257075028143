.authLayout {
  background-color: #ffff;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 850px) {
    display: none;
  }
}

.titleHeader {
  font-size: 32px !important;
  line-height: 40px !important;
  font-weight: bold !important;

  @media (max-width: 850px) {
    font-size: 26px !important;
    line-height: 33px !important;
  }
}

.cardLogo {
  overflow: hidden !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
  /* border-radius: 4px; */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.2), inset 0 0 2px rgba(255, 255, 255, 0.5) !important;
  /* margin-top: 30px; */
}